<template>
  <validation-provider
    :mode="vmode"
    :vid="vid"
    :name="name"
    :rules="rules"
    v-slot="{ dirty, validated, valid, errors }"
  >
    <b-form-group
      :id="`input-group-${id}`"
      :label="grplbl"
      :label-for="`input-${id}`"
      :class="{
        asterisk:
          Object.keys(rules).includes('required') ||
          (Object.keys(rules).includes('required_if') && !valid),
      }"
    >
      <!-- @click.native="$emit('interaction')" -->
      <b-form-checkbox
        :id="`input-${id}`"
        :name="`input-${id}`"
        v-model="inputVal"
        @change="$emit('change', $event, inputVal)"
        :value="chk_val"
        :unchecked-value="unchk_val"
        :state="getValidationState({ dirty, validated, valid, errors })"
        :aria-describedby="`input-${id}-live-feedback`"
        :disabled="disabled"
        :switch="isSwitch"
        >{{ label }}</b-form-checkbox
      >
      <b-form-invalid-feedback
        :id="`input-${id}-live-feedback`"
        class="d-block"
        >{{ errors[0] }}</b-form-invalid-feedback
      >
    </b-form-group>
  </validation-provider>
</template>
<script>
// import {
//     extend
// } from "vee-validate";
export default {
  props: {
    disabled: {
      value: Boolean,
      default: false,
    },
    value: undefined,
    chk_val: undefined,
    unchk_val: undefined,
    vid: String,
    name: String,
    label: String,
    rules: {
      type: Object,
      default() {
        return {};
      },
    },
    vmode: {
      type: String,
      default: "aggressive",
    },
    isSwitch: {
      value: Boolean,
      default: false,
    },
    groupLabel: String,
  },
  data() {
    return {
      id: null,
    };
  },
  mounted() {
    this.id = this._uid;
    // handle un-checking that set value to false...
    // extend('required', {
    //     validate (value) {
    //         return {
    //             required: true,
    //             valid: !['', null, undefined, false].includes(value)
    //         };
    //     },
    //     computesRequired: true
    // });
  },
  computed: {
    inputVal: {
      get() {
        // console.debug(
        //   `BASE CHECKBOX inputVal GET: ${JSON.stringify(this.value)}`
        // );
        return this.value;
      },
      set(val) {
        // console.debug(`BASE CHECKBOX inputVal SET: ${JSON.stringify(val)}`);
        this.$emit("input", val);
      },
    },
    grplbl() {
      // return this.isSwitch == "" ? this.label : this.switchLabel;
      return this.groupLabel ?? this.label;
    },
  },
  // watch: {
  //     value() {
  //         this.$emit('input', this.value);
  //     }
  // },
  methods: {
    // getValidationState({ dirty, validated, valid = null }) {
    //   return dirty || validated ? valid : null;
    // },
    getValidationState({ dirty, validated, valid = null, errors = null }) {
      if (errors[0]) return false;
      // return dirty || validated ? valid : null;
      return !Object.keys(this.rules).length
        ? null
        : dirty || validated
        ? valid
        : null;
    },
  },
};
</script>
